.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid{
    width: 100%;
    height: 80vh;
    position: relative;
}

.hero-mid h1{
    padding-top: 4rem !important;
}

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    width: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.hero-text h1{
    font-size: 4rem;
    font-weight: 700;
    background: #fff;
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p{
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    padding: 2rem 5rem 4.5rem;
}

.hero-text .show{
    text-decoration: none;
    background: #fff;
    padding: 0.6rem 1rem;
    border-radius: px;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
    margin-top: 1rem;
}

.hero-text a{
    border-radius: 0.8rem;
}

@media screen and (max-width:550px) {
    .hero-text h1{
        padding: 10px 20px;
    }

    .hero-text p{
        padding: 0 0 2rem 0;
    }

    .hero-text show{
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1.1rem;
    }
}