.tripUp{
    margin: 2rem;
    color: white;
}

.tripUp p{
    font-size: 1.5rem;
}

.tripcard{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.t-card1{
    width: 22%;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
    background-color: rgb(34, 43, 50);
    margin-top: 0;
}

.t-card1 h4{
    font-size: 1.8rem;
    padding: 1rem 0 1rem 0;
}

.t-card1 p{
    padding: 1rem ;
}

@media screen and (max-width:850px) {
    .tripUp{
        margin: 4rem 2rem;
    }
    .tripcard{
        flex-direction: column;
    }
    .t-card1{
        width: 100%;
        margin-bottom: 1.5rem;
    }
}

