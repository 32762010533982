.AboutData{
    margin: 2rem;
}

.AboutData h2{
    font-size: 3rem;
    padding: 1rem;
}

.AboutData p{
    font-size: 1.5rem;
    padding: 1rem 3.5rem;
}

.AboutData h3{
    font-size: 2rem;
    margin: 0rem 0 1rem;
}

.AboutData span{
    color: red;
}

.AboutData h1{
    font-size: 2.3rem;
    padding: 1rem;
}

.destinationUp{
    margin: 4rem 4rem;
}

.destinationUp h1{
    font-size: 3.5rem;
}

.destinationUp p{
    font-size: 1.5rem;
}

.first-des2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.3);
    padding: 1rem .8rem;
    text-align: start;
    border-radius: 20px;
    cursor: pointer;
    background-color: aliceblue;
}

.des-text2{
    width: 80%;
    text-align: center;
    font-size: 1.5rem;
    padding-left: 2rem;
}

.des-text2 h2{
    padding-bottom: 0rem;
}

.des-text2 h3{
    padding-bottom: 0rem;
}

.image2{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    margin-left: 10rem;
    overflow: hidden;
}

.image2 img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    transition: 0.3s ease-in-out;
}

.image2:hover img{
    transform: scale(1.5);
}

@media screen and (max-width:850px) {
    .destinationUp{
        margin: 4rem 2rem;
    }

    .first-des2{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .first-des-rev{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .des-text2{
        width: 100%;
        padding-left: 1rem;
    }

    .image2{
        width: 100%;
        margin: 1.8rem 0;
    }

    .image2 img{
        height: 400px;
    }
}