.destination{
    margin: 4rem 6rem;
}

.destination h1{
    font-size: 3.5rem;
}

.destination p{
    font-size: 1.5rem;
}

.first-des{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.3);
    padding: 1rem .8rem;
    text-align: start;
    border-radius: 7px;
    cursor: pointer;
    background-color: aliceblue;
}

.des1-text{
    width: 55%;
    text-align: center;
    font-size: 1.5rem;
    padding-left: 5rem;
}

.des1-text h2{
    padding-bottom: 1rem;
}

.image1{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    margin-left: 15rem;
    overflow: hidden;
}

.image1 img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    transition: 0.3s ease-in-out;
}

.image1:hover img{
    transform: scale(1.5);
    border-radius: 16px;
}

@media screen and (max-width:850px) {
    .destination{
        margin: 4rem 2rem;
    }

    .first-des{
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        
    }

    .first-des-rev{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .des1-text{
        width: 100%;
        padding-left: 0;
    }

    .image1{
        width: 100%;
        margin: 1.8rem 0;
    }

    .image1 img{
        height: 250px;
    }
}