.form-container{
    margin: 4rem 6rem;
    color: #2a2a2a;
    font-size: 1.3rem;
}

.form-container form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    font-size: 1.3rem;
}

.form-container input{
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
    font-size: 1.1rem;
}

.form-container textarea{
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
    font-size: 1.1rem;
}

.form-container button{
    height: 4rem;
    font-size: 1.3rem;
    display: inline;
    width: 30%;
    margin-left: 16rem;
    padding: 1rem;
    cursor: pointer;
    border: 2px solid white;
    color: white;
    background-color: #2a2a2a;
    border-radius: 0.5rem;
}

@media screen and (max-width:850px) {
    .form-container{
        margin: 4rem 6rem;
    }

    .form-container form{
        padding-top: 2rem;
        width: 90%;
    }

    .form-container button{
        width: 50%;
        margin-left: 30%;
    }
}