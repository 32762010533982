@import url('https://fonts.googleapis.com/css2?family=Ysabeau:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ysabeau', sans-serif;
}

.App {
  text-align: center;
}

