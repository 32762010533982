.NavbarItems{
    background-color: rgba(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    width: 97%;
    height: 70px;
    border-radius: 20px;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
}

.navbar-logo {
    height: 4rem; /* Adjusted from 6rem to 4rem */
    width: auto; /* Changed from 25rem to auto for responsive sizing */
    cursor: pointer;
    margin-left: 30px;
    border-radius: 20px;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1.5rem;
    font-weight: 800;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i{
    padding-right: 10px;
}

.nav-links:hover{
    background-color: rgb(80, 97, 114);
    color: #fff;
    border-radius: 5px;
    transition: 0.3s;
}

.menu-icons{
    visibility: hidden;
}

@media screen and (max-width:850px) {
    .nav-menu{
        z-index: -1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        background-color: #fff;
        transition: 0.3s ease-in-out;
    }

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .nav-links:hover{
        background-color: #01959a;
        transition: none;
    }

    .menu-icons{
        visibility: visible;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    }
}